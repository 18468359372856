import React from "react";
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";

const PublicListEmptyAlert = ({ publicListEmptyAlert }) => {
  const { t } = useTranslation();

  return (
    <div className={`public-list-empty ${publicListEmptyAlert? "is-empty" : "not-empty"}`}>
      <Typography style={{fontSize: 13, textAlign: 'center', width: "100%"}}>{t("Public places have yet to be posted.")}</Typography>
    </div>
  );
};

export default PublicListEmptyAlert;