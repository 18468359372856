import React from "react";
import { simple, hazard } from "./MapStyles";
import "../../styles/base.scss";
import "../../styles/map.scss";
import { withTranslation } from "react-i18next";

class HazardMap extends React.Component {
  state = {
    showDropdown: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps && prevProps.mapStyle !== this.props.mapStyle) {
      this.getMapStyle();
    }
  }

  componentDidMount() {
    if (!window.google) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD7WC_mBt8r9GXRw7-ZYYGWweMB02D9y5w`;
      let topLevelScript = document.getElementsByTagName("script")[0];
      topLevelScript.parentNode.insertBefore(script, topLevelScript);

      script.addEventListener("load", () => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }

    // Add global click listener to detect clicks outside
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    // Remove global click listener when unmounting
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    // If we haven't created them yet, bail out
    if (
      !this.settingsBtnUI ||
      !this.settingsDropdownUI ||
      !this.mapStyleButtons
    ) {
      return;
    }

    // Check if the click is outside our 3 key elements
    const clickedOutside =
      !this.settingsBtnUI.contains(event.target) &&
      !this.settingsDropdownUI.contains(event.target) &&
      !this.mapStyleButtons.contains(event.target);

    // If outside and currently shown, close it
    if (clickedOutside && this.state.showDropdown) {
      this.setState({ showDropdown: false }, () => {
        this.settingsDropdownUI.className = `settings-dropdown-border show-dropdown-${this.state.showDropdown}`;
        this.mapStyleButtons.className = `map-styles-container slide-${this.state.showDropdown}`;
      });
    }
  };

  getMapStyle = () => {
    const { mapStyle } = this.props;
    let mapType;

    switch (mapStyle) {
      case "simple":
        mapType = simple;
        break;
      case "hazard":
        mapType = hazard;
        break;
      default:
        mapType = mapStyle;
        break;
    }

    if (mapType) {
      if (mapType === "satellite") {
        this.map.setOptions({
          controlSize: 30,
          mapTypeId: "satellite",
          styles: "simple"
        });
      } else if (mapType === "terrain") {
        this.map.setOptions({
          controlSize: 30,
          mapTypeId: "terrain",
          styles: "simple"
        });
      } else {
        this.map.setOptions({
          controlSize: 30,
          mapTypeId: "roadmap",
          styles: mapType
        });
      }
    }
  };

  onScriptLoad = () => {
    this.map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );

    this.getMapStyle();

    /* Custom UI Elements */
    const settingsBtn = document.createElement("div");
    const settingsDropdown = document.createElement("div");
    const searchBox = document.createElement("div");

    this.hazardMapSettings(settingsBtn, settingsDropdown, searchBox, this.map);

    settingsBtn.index = 1;
    settingsDropdown.index = 1;
    searchBox.index = 1;

    this.map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
      settingsBtn
    );
    this.map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
      settingsDropdown
    );
    this.map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      searchBox
    );

    this.props.onMapLoad(this.map);
  };

  hazardMapSettings = (settingsBtn, settingsDropdown, searchBox, map) => {
    const { t, i18n } = this.props;

    // Border
    const settingsBtnUI = document.createElement("div");
    settingsBtnUI.className = "settings-btn-border";

    // Interior
    const settingsBtnText = document.createElement("div");
    settingsBtnText.className = "settings-btn-text";
    settingsBtnText.innerHTML = t("Map Settings");

    // Dropdown Menu
    const settingsDropdownUI = document.createElement("div");
    settingsDropdownUI.className = "settings-dropdown-border show-dropdown-false";

    const settingsDropdownOptionOne = document.createElement("div");
    const settingsDropdownOptionTwo = document.createElement("div");
    settingsDropdownOptionOne.className = "settings-dropdown-text";
    settingsDropdownOptionOne.innerHTML = t("Set Default");
    settingsDropdownOptionTwo.className = "settings-dropdown-text";
    settingsDropdownOptionTwo.innerHTML = t("Reset to Default");

    // SearchBox
    const searchBoxInput = document.createElement("input");
    searchBoxInput.id = "pac-input";
    searchBoxInput.className = "controls";
    searchBoxInput.setAttribute("type", "text");
    const currentLanguage = i18n.language;

    var options = {
      componentRestrictions: {
        country: currentLanguage === "en" ? "US" : "JP"
      }
    };

    const search = new window.google.maps.places.Autocomplete(
      searchBoxInput,
      options
    );
    this.enableEnterKey(searchBoxInput);

    /* Append the elements */
    settingsBtn.appendChild(settingsBtnUI);
    settingsBtnUI.appendChild(settingsBtnText);

    settingsDropdown.appendChild(settingsDropdownUI);
    settingsDropdownUI.appendChild(settingsDropdownOptionOne);
    settingsDropdownUI.appendChild(settingsDropdownOptionTwo);

    searchBox.appendChild(searchBoxInput);

    /* Store references so handleClickOutside can use them */
    this.settingsBtnUI = settingsBtnUI;           // button border
    this.settingsDropdownUI = settingsDropdownUI; // dropdown
    // The .map-styles-container is rendered in the parent; grab it here:
    const mapStyleButtons = document.getElementsByClassName(
      "map-styles-container"
    )[0];
    this.mapStyleButtons = mapStyleButtons;

    /* Click Events */

    // Toggle dropdown visibility and map style buttons
    settingsBtnUI.addEventListener("click", () => {
      this.setState(
        (prevState) => ({ showDropdown: !prevState.showDropdown }),
        () => {
          settingsDropdownUI.className = `settings-dropdown-border show-dropdown-${this.state.showDropdown}`;
          mapStyleButtons.className = `map-styles-container slide-${this.state.showDropdown}`;
        }
      );
    });

    // "Set Default" -> opens settings
    settingsDropdownOptionOne.addEventListener("click", () => {
      this.props.handleOpenSettings();
    });

    // "Reset to Default"
    settingsDropdownOptionTwo.addEventListener("click", () => {
      this.props.restoreDefaults();
    });

    // SearchBox Map Event
    search.addListener("place_changed", () => {
      const place = search.getPlace();

      if (window.searchMarkers) {
        window.searchMarkers.setMap(null);
      }

      if (!place.geometry) return;

      window.searchMarkers = new window.google.maps.Marker({
        map,
        animation: window.google.maps.Animation.DROP,
        title: place.name,
        position: place.geometry.location
      });

      window.searchMarkers.addListener("click", () => {
        window.searchMarkers.setMap(null);
      });

      const customZoomLevel =
        this.props.userSettings && this.props.userSettings.initialZoom
          ? parseInt(this.props.userSettings.initialZoom)
          : 10;

      map.setCenter(place.geometry.location);
      map.setZoom(customZoomLevel);
      this.props.getPlacesByArea();
    });
  };

  enableEnterKey = (input) => {
    const _addEventListener = input.addEventListener;

    const addEventListenerWrapper = (type, listener) => {
      if (type === "keydown") {
        const _listener = listener;
        listener = (event) => {
          const suggestionSelected = document.getElementsByClassName(
            "pac-item-selected"
          ).length;

          if (event.key === "Enter" && !suggestionSelected) {
            const e = new KeyboardEvent("keydown", {
              key: "ArrowDown",
              code: "ArrowDown",
              keyCode: 40
            });

            _listener.apply(input, [e]);
          }
          _listener.apply(input, [event]);
        };
      }
      _addEventListener.apply(input, [type, listener]);
    };
    input.addEventListener = addEventListenerWrapper;
  };

  render() {
    return <div style={{ width: "100%", height: "100%" }} id={this.props.id} />;
  }
}

export default withTranslation()(HazardMap);
